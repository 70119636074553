<template>
  <div class="box">
    <div class="top_bg">
      <!--    表单-->
      <div class="input_box model_item1" ref="formBox" style="margin-top: 60vh">
        <img src="../assets/a1.png" style="width: 86%;height: auto;">
        <van-form validate-first style="width: 92%;">
          <!-- 通过 pattern 进行正则校验 -->
          <van-field
              v-model="name"
              name="name"
              placeholder="请输入姓名"
              label="姓名"
              maxlength="10"
              @input="checkSubmit"
              :rules="[{required: true, message: '' }]"
          />
          <van-field
              v-model="phone"
              name="phone"
              placeholder="请输入手机号"
              label="手机号"
              type="number"
              maxlength="11"
              @input="checkSubmit"
              :rules="[{ patternPhone, message: '' }]"
          />



          <van-field
              v-model="cityName"
              name="city"
              is-link
              readonly
              @input="checkSubmit"
              placeholder="请选择工作城市"
              label="工作城市"
              @click="showArea = true"
              :rules="[{ required:true, message: '' }]"
          />
          <van-field
              v-model="carNumber"
              name="carNumber"
              placeholder="请输入车牌号"
              maxlength="11"
              label="车牌号"
              is-link
              readonly
              @click="showCardBoard = true"
              :rules="[{required: true, message: '' }]"
          />

<!--          <div class="div_row" style="border-bottom: 1px solid #ebedf0;align-items: center">-->
<!--            <span class="input_label">购车方式</span>-->
<!--            <div class="div_row" style="height: 20px;">-->
<!--              <van-tag color="#FF446C" @click="buyCarType=0" :plain="buyCarType===0?false:true" type="primary" size="18">全款</van-tag>-->
<!--              <van-tag color="#FF446C" @click="buyCarType=1" :plain="buyCarType===1?false:true" type="primary" size="18" style="margin-left: 5px">车贷-已结清</van-tag>-->
<!--              <van-tag color="#FF446C" @click="buyCarType=2" :plain="buyCarType===2?false:true" type="primary" size="18" style="margin-left: 5px">车贷-未结清</van-tag>-->
<!--            </div>-->

<!--            &lt;!&ndash;            <van-radio-group v-model="buyCarType" direction="horizontal">&ndash;&gt;-->
<!--            &lt;!&ndash;              <van-radio name="1">全款车</van-radio>&ndash;&gt;-->
<!--            &lt;!&ndash;              <van-radio name="2">有按揭</van-radio>&ndash;&gt;-->
<!--            &lt;!&ndash;            </van-radio-group>&ndash;&gt;-->
<!--          </div>-->

          <van-field
              v-model="smsCode"
              center
              clearable
              maxlength="6"
              label="短信验证码"
              placeholder="请输入短信验证码"
          >
            <template #button>
              <van-button plain size="mini" :plain="false" type="primary" :disabled="disabledSms" color="#FF446C" @click="sendSmsCode">{{codeTime}}</van-button>
            </template>
          </van-field>

          <img src="../assets/btn.gif" @click="submit" style="height: auto;width: 100%;">
          <van-checkbox v-model="checked" style="margin-left: 16px;margin-top: 0px;padding-bottom: 20px;">
            <div class="xy_box">
              同意我们的<a href="https://cxh.h5.cqyjs.top/ys.html">《用户隐私协议》</a><a href="https://cxh.h5.cqyjs.top/zc.html">《注册协议》</a>
              <a :href="loginPushAgreement">《个人信息共享授权书》</a> <a href="https://file.grjianr.com/20230426/5677f43e0b724576847340e54ac1921e.html">《个人信息共享授权书》</a>
            </div>
          </van-checkbox>
          <!--          <van-button @click="submit" round type="info" color="#FF5930" :disabled="loginEnabled" style="margin-top: 16px;margin-bottom: 16px;width: 100%;">立即申请</van-button>-->
        </van-form>
      </div>
<!--      内容-->
      <div class="model_item2">
        <div style="width: 92vw;margin-top: 5px;">
          <img src="../assets/x1.png" style="width: 100%;height: auto;margin-top: 15px;">
<!--          <img src="../assets/x2.png" style="width: 100%;height: auto;margin-top: 15px;">-->
<!--          <img src="../assets/x3.png" style="width: 100%;height: auto;margin-top: 15px;">-->
<!--          <img src="../assets/x4.png" style="width: 100%;height: auto;margin-top: 15px;">-->
<!--          <img src="../assets/x10.png" style="width: 100%;height: auto;margin-top: 12px;">-->
<!--          <img src="../assets/x11.png" style="width: 100%;height: auto;margin-top: 12px;">-->
          <img src="../assets/x12.png" style="width: 100%;height: auto;margin-top: 12px;">

          <div style="font-size: 12px;color: #3E0608;margin-top: 32px;width: 100%;display: flex;flex-direction: column;justify-content: center;align-items: center">
            <div style="width: 60%;text-align: center">
              *温馨提示: 您在页面提交贷款申请后，合作机构工作人员将与您联系并提供个性化贷款服务方案。
              您的贷款资金可用于日常消费或企业经营，请确保您的贷款用途符合国家相关政策法规。
            </div>

            <img src="../assets/x5.png" style="width: 18px;height: 18px;margin-top: 30px;">
            <div style="font-size: 13px;color: #3E0608;margin-top: 7px;">
              官方客服电话
            </div>
            <div style="font-size: 13px;color: #3E0608;">
              400-1818-973
            </div>
            <div style="font-size: 12px;color: #3E0608;justify-content: center;align-items: center;margin-top: 20px;padding-bottom: 13vh;" class="div_col">
              <span>渝ICP备2021011792号</span>
              <span>重庆易巨盛科技有限公司</span>
              <div class="div_row" style="align-items: center;justify-content: center">
                <img src="../assets/gong_an.png" style="margin-right: 5px;">
                <a href="https://www.beian.gov.cn/portal/registerSystemInfo" style="font-size: 12px;color: #3E0608;">渝公网安备 50010802005461号</a></div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <img src="../assets/db_btn.png" v-if="showBtn"  @click="submit" class="buttomBtn">


    <!--    地区-->
    <van-popup v-model="showArea" round position="bottom">
      <van-cascader
          v-model="cityName"
          title="请选择所在地区"
          :options="cityCodeList"
          @close="showArea = false"
          :field-names="cityFieldNames"
          @finish="onCityFinish"
      />
    </van-popup>
<!--同意协议-->
    <van-popup v-model:show="showAgreement">
      <div style="width: 60vw;padding: 10px;border-radius: 10px;display: flex;flex-direction: column;justify-content: center;align-items: center;">
        <div style="font-size: 18px;font-weight: bold;color: #333">请阅读并同意以下协议</div>
        <div style="font-size: 18px;font-weight: bold;color: #333">
          <div class="xy_box" style="margin-top: 20px;">
            <a href="https://cxh.h5.cqyjs.top/ys.html">《用户隐私协议》</a><a href="https://cxh.h5.cqyjs.top/zc.html">《注册协议》</a>
            <a :href="loginPushAgreement">《个人信息共享授权书》</a> <a href="https://file.grjianr.com/20230426/5677f43e0b724576847340e54ac1921e.html">《个人信息共享授权书》</a>
          </div>
          <van-button @click="agree" round type="info" color="#FF5930" style="margin-top: 16px;margin-bottom: 16px;width: 100%;">同意并继续</van-button>
        </div>
      </div>
    </van-popup>

    <van-popup v-model:show="loading">
      <div style="height: 60px;width: 60px;display: flex;flex-direction: column;justify-content: center;align-items: center">
        <van-loading color="#FF5930" />
        <span style="font-size: 12px;color: #FF5930;">加载中</span>
      </div>
    </van-popup>

    <!-- 车牌号软键盘 -->
    <van-action-sheet v-model="showCardBoard" get-container="body">
      <div class="vnp-header">
        <button type="button" class="vnp-btn-finish" @click="finish">完成</button>
      </div>
      <div class="vnp-input-box-outer">
        <div class="vnp-input-box" :class="{'vnp-red':activeIndex<=6}">
          <ul>
            <li v-for="(item, index) in val" :key="index"
                :class="{ active: activeIndex === index }" @click="handleClickItem(index)">
              <span>{{ item }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="vnp-keys">
        <div class="vnp-keys-row" v-for="(item, index) in keyboardList" :key="index">
          <div class="vnp-btn-key-wrapper" v-for="(val, index) in item" :key="index" :class="{
                        'vnp-del-wrapper': val === 'del',
                        'vnp-type-wrapper': val === 'type',
                    }">
            <van-button v-if="val === 'type'" class="vnp-btn-key" @click="handleChangeType">
              <span v-if="type === 'cn'">中/<span class="vnp-smaller">英</span></span>
              <span v-else><span class="vnp-smaller">中</span>/英</span>
            </van-button>
            <van-button v-else-if="val === 'del'" class="vnp-btn-key" @click="handleDel">
              <svg viewBox="0 0 32 22" xmlns="http://www.w3.org/2000/svg" class="vnp-delete-icon">
                <path
                    d="M28.016 0A3.991 3.991 0 0132 3.987v14.026c0 2.2-1.787 3.987-3.98 3.987H10.382c-.509 0-.996-.206-1.374-.585L.89 13.09C.33 12.62 0 11.84 0 11.006c0-.86.325-1.62.887-2.08L9.01.585A1.936 1.936 0 0110.383 0zm0 1.947H10.368L2.24 10.28c-.224.226-.312.432-.312.73 0 .287.094.51.312.729l8.128 8.333h17.648a2.041 2.041 0 002.037-2.04V3.987c0-1.127-.915-2.04-2.037-2.04zM23.028 6a.96.96 0 01.678.292.95.95 0 01-.003 1.377l-3.342 3.348 3.326 3.333c.189.188.292.43.292.679 0 .248-.103.49-.292.679a.96.96 0 01-.678.292.959.959 0 01-.677-.292L18.99 12.36l-3.343 3.345a.96.96 0 01-.677.292.96.96 0 01-.678-.292.962.962 0 01-.292-.68c0-.248.104-.49.292-.679l3.342-3.348-3.342-3.348A.963.963 0 0114 6.971c0-.248.104-.49.292-.679A.96.96 0 0114.97 6a.96.96 0 01.677.292l3.358 3.348 3.345-3.348A.96.96 0 0123.028 6z"
                    fill="currentColor"></path>
              </svg>
            </van-button>
            <van-button v-else class="vnp-btn-key" :class="{ 'vnp-btn-empty': !val }"
                        @click="handleClickKey(val)">
              {{ val }}
            </van-button>
          </div>
        </div>
      </div>
    </van-action-sheet>


    <!-- 提交成功弹窗组件 -->
    <van-dialog
        v-model="showDialog"
        :show-confirm-button="false"
        confirm-button-text=""
        confirm-button-color="#4e97ff"
    >
      <div class="div_col_center" style="text-align: center">
        <img src="../assets/success.png" style="width: 64px;height: 64px;margin-top: 15px;">
        <div style="color: #333;font-size: 18px;font-weight: bold;margin-top: 12px;">恭喜您，提交成功</div>
        <div style="color: #666;font-size: 16px;margin-top: 12px;">
          请保持手机畅通，并留意<span style="color: #4e97ff">(重庆来电)</span>的电话来电，车享花工作人员将与您联系。
        </div>
        <div style="color: #999;font-size: 12px;margin-top: 12px;">温馨提示：如您已开启防来电骚扰功能，请务必提前关闭，避免电话拦截导致呼叫失败</div>
      </div>
    </van-dialog>

<!--    进入页面弹出-->
    <van-overlay :show="showInfo" @click="showInfo=false">
      <div class="wrapper" @click.stop>
        <div class="wrapper_info">
<!--          <div>-->
<!--            请知悉本产品 <span style="color: #f00">无服务费</span>,故在整个融资过程(包含融资前、中、后)都不会以任何形式收取您的服务费。-->
<!--          </div>-->
          <div class="wrapper_t1">
            <span style="color: #f00;margin-right: 5px;">*</span>请认真阅读以下告知后再申请
          </div>

          <div class="wrapper_check">
            <div style="flex: 1;margin-left: 5px;">
              在本次融资过程里我方不会收取您的任何<span style="color: #f00">中介服务费</span>。如有被收取费用<span style="color: #f00">请暂停申请</span>，且请点击下方“<span style="color: #f00">举报</span>”联系我们24小时热线4001818973。
            </div>
          </div>

          <div class="wrapper_btn">
            <van-button plain type="info" style="flex: 1;" @click="juBao">举报</van-button>
            <div style="width: 40px"></div>
            <van-button type="info" style="flex: 1;" @click="submitInfo">已确认</van-button>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
// import { areaList } from '@vant/area-data';
import {cityCodeList} from "@/city"
import FingerprintJS from 'fingerprintjs2';
import {doLoginWithout,home,sendSms} from '@/api/index.js'
import { Notify } from 'vant';
import { Toast } from 'vant';

var codeInterval
export default {
  name: "index",
  data(){
    return{
      showInfo:true,
      loginPushAgreement:"https://bjjftest.hzbxhd.com/html/agreement/inforSharing.html",//落地页协议推送
      showDialog:false,
      showBtn:false,
      boxImgHeight:0,
      pushCode:"",
      name:"",
      phone:"",
      carNumber:"",
      cityName:"",
      cityCode:"",
      showArea:false,
      buyCarType:0,
      patternPhone: /^1\d{10}$/,
      cityCodeList:cityCodeList,//地址列表
      checked:false,
      loginEnabled:true,
      deviceId:"",//设备id
      cityFieldNames: {
        text: 'name',
        value: 'adcode',
        children: 'city',
      },
      showAgreement:false,
      loading:false,
      showCardBoard:false,
      smsCode:"",
      disabledSms:false,
      codeTime:"获取验证码",
      val: ['', '', '', '', '', '', '', ''], //固定八位
      activeIndex: 0, //当前活动的软键盘按钮
      type: 'cn',
      cn: [
        ['京', '津', '沪', '渝', '冀', '豫', '云', '辽', '黑', '湘'],
        ['皖', '鲁', '新', '苏', '浙', '赣', '鄂', '桂', '甘', '晋'],
        ['蒙', '陕', '吉', '闽', '贵', '粤', '青', '藏', '川', '宁'],
        ['type', '琼', '使', '领', '学', '警	', '挂', '', 'del'],
      ],
      en: [
        ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
        ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'O', 'P'],
        ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
        ['type', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', 'del'],
      ],
      //需要特殊处理的code
      channelCodes:["AJBEF3","IAEV7N","MUIAVB","FBUEZQ"],
      showChannelTitle:false,
    }
  },
  computed: {
    keyboardList() {
      return this.type === 'en' ? this.en : this.cn;
    },
  },
  watch: {
    activeIndex() {
      this.handleActiveChange(this.activeIndex);
    },
  },
  mounted() {
    // 在页面滚动时监听滚动事件
    window.addEventListener('scroll', this.handleScroll);
    let formBoxHeight = this.$refs.formBox.clientHeight;
// 获取页面可视区域的高度
    let viewportHeight = window.innerHeight;
    this.boxImgHeight =formBoxHeight+viewportHeight*0.7
  },
  created() {
    this.pushCode = this.$route.params.code;
    console.log("当前渠道号:",this.pushCode);
    //判断当前渠道号是否是特殊渠道号
    if (this.channelCodes.includes(this.pushCode)) {
      document.title = "简单融呗-车享花";
      this.showChannelTitle = true;
    }
    this. getDeviceId();
    this.home()
  },
  beforeDestroy(){
    if(codeInterval!=null){
      clearInterval(codeInterval); //清除定时器
    }
  },
  destroyed() {
    // 在组件销毁时移除滚动事件监听
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods:{
    juBao(){
      this.showInfo = false;
      window.location.href = 'tel:4001818973';
    },
    submitInfo(){
      this.showInfo = false
    },
    //地址
    onCityFinish({ selectedOptions }){
      this.showArea = false;
      this.cityName = selectedOptions.map((option) => option.name).join('/');
      this.cityCode = selectedOptions.map((option) => option.adcode).join('/');
      this.checkSubmit()
    },
    validatorPhone(val) {
      return /^1\d{10}$/.test(val);
    },
    checkSubmit(){
      this.loginEnabled = !(this.name && this.validatorPhone(this.phone) && this.cityName);
    },
    agree(){
      this.checked = true;
      this.showAgreement = false;
      this.submit();
    },
    submit(){
      if(!this.name){
        Notify({ type: 'danger', message: '请输入名字' });
        return
      }
      if(!this.phone){
        Notify({ type: 'danger', message: '请输入手机号' });
        return
      }
      if(!this.smsCode){
        Notify({ type: 'danger', message: '请输入验证码' });
        return
      }
      if(!this.cityCode){
        Notify({ type: 'danger', message: '请选择工作城市' });
        return
      }
      if(!this.carNumber){
        Notify({ type: 'danger', message: '请输入车牌号码' });
        return
      }
      // if(this.buyCarType<0){
      //   Notify({ type: 'danger', message: '请选择购车方式' });
      //   return
      // }
      // if(!this.checkCarNumber(this.carNumber)){
      //   Notify({ type: 'danger', message: '车牌号码错误' });
      //   return
      // }
      if(!this.checked){
        this.showAgreement = true
        return;
      }else {
        this.showAgreement = false
      }
      let data = {
        channelCode: this.pushCode,
        name: this.name,
        mobile: this.phone,
        deviceId: this.deviceId,
        cityName: this.cityName,
        cityCode: this.cityCode,
        carNumber: this.carNumber,
        // buyCarType: this.buyCarType,
        buyCarType: 0,
        smsCode:this.smsCode,
      }
      var that = this;
     // setTimeout(function (){
     //   that.loading = true;
     // },500)
      doLoginWithout(data).then(response=>{
        console.log("注册返回",response)
        if(response.data.code===200){
          that.showDialog = true;
          setTimeout(function (){
            that.showDialog = false;
            that.$router.push({
              name: 'result',
            })
          },2000)
        }else {
          Toast.fail(response.data.msg)
        }

      }).catch(err=>{
        this.loading = false;
        console.error("注册异常",err)
        Notify({ type: 'danger', message: '注册异常'+err.toString() });
      })
    },
    getDeviceId(){
      var that = this;
      FingerprintJS.get((components) => {
        const values = components.map((component) => component.value);
        const deviceId = FingerprintJS.x64hash128(values.join(''), 31);
        console.log(deviceId); // 打印设备ID
        that.deviceId = deviceId;
      });
    },
    showKeyboard() {
      this.keyboardVisible = true;
    },
    onKeyboardInput(value) {
      this.licensePlate += value;
    },
    onKeyboardDelete() {
      this.licensePlate = this.licensePlate.slice(0, -1);
    },
    //埋点
    home(){
      let data = {
        channelCode: this.pushCode,
        ua: window.navigator.userAgent,
        deviceId: this.deviceId,
      }
      home(data).then(response=>{
        console.log("埋点返回",response)
        if(response.data.code===200){
          if(response.data.data){
            this.loginPushAgreement = response.data.data.loginPushAgreement;
            console.log("推送协议",this.loginPushAgreement)
          }
        }
        this.loading = false;
      }).catch(err=>{
        this.loading = false;
        console.error("埋点返回异常",err)
        // Notify({ type: 'danger', message: '注册异常'+err.toString() });
      })
    },

    // 点击某个输入框
    handleClickItem(index) {
      this.activeIndex = index;
    },
    // 中英文切换
    handleChangeType() {
      this.type = this.type === 'en' ? 'cn' : 'en';
    },
    // 选中某个键盘按钮值
    handleClickKey(val) {
      if (val) {
        this.$set(this.val, this.activeIndex, val);

        if (this.activeIndex < 7) {
          this.activeIndex += 1;
        }
      }
    },
    // 获焦顶部要填入的单元格
    handleActiveChange(activeIndex) {
      if (activeIndex === 0) {
        this.type = 'cn';
      } else {
        this.type = 'en';
      }
    },
    handleDel() {
      this.$set(this.val, this.activeIndex, '');
      if (this.activeIndex > 0) {
        this.activeIndex -= 1;
      }
    },
    finish() {
      const _val = this.val.join('');
      if (_val.length <= 6) return;
      this.showCardBoard = false;
      this.carNumber = _val
    },
    sendSmsCode(){
      if(!this.phone||!this.validatorPhone(this.phone)){
        Toast.fail('请输入正确的手机号');
        return
      }
      let time = 60;
      let that = this;
      this.disabledSms = true;
      codeInterval = setInterval(function (){
        time--;
        if (time >= 0) {
          that.codeTime = time + "秒";
        } else if (time < 0) {
          that.codeTime = "重新发送";
          that.disabledSms = false; //倒计时结束能够重新点击发送的按钮
          clearInterval(codeInterval); //清除定时器
        }
      },1000)
      let data = {
        phone: this.phone
      }
      sendSms(data).then(response=>{
        console.log("发送短信",response)
        if(response.data.code===200){
          Toast.success('发送成功');
        }else {
          Toast.fail('发送失败:'+response.data.msg);
          that.codeTime = "重新发送";
          that.disabledSms = false; //倒计时结束能够重新点击发送的按钮
          clearInterval(codeInterval); //清除定时器
        }
      }).catch(err=>{
       console.log("发送短信异常:",err)
        Toast.fail('发送失败');
        that.codeTime = "重新发送";
        that.disabledSms = false; //倒计时结束能够重新点击发送的按钮
        clearInterval(codeInterval); //清除定时器
        // Notify({ type: 'danger', message: '注册异常'+err.toString() });
      })
    },
    handleScroll() {
      // 获取页面滚动的距离
      const scrollY = window.scrollY || window.pageYOffset;

      // 获取页面可视区域的高度
      const viewportHeight = window.innerHeight;

      // 获取页面的实际高度
      const pageHeight = document.body.scrollHeight;
      console.log(scrollY,viewportHeight,pageHeight)
      // 判断是否滚动超出一屏
      this.showBtn = (scrollY>viewportHeight);
    }
  }
}
</script>


<style scoped>

@keyframes shake {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}
.btn_image {
  animation: shake 1s infinite; /* 1s 是动画持续时间，infinite 表示无限循环 */
}
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
}
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
input::-webkit-clear-button {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}
input:focus {
  outline: none;
  box-shadow: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  /* 去掉阴影效果 */
  box-shadow: 0 0 0px 1000px white inset;
  /* 兼容性更好的写法 */
}

  .box{
    width: 100vw;
    height: auto;
    overflow-x: hidden !important;
  }
  .top_div{
    background: linear-gradient(to bottom, #FEAD8D, #FE9267);
    width: 100vw;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
  }
  .top_bg{
    width: 100vw;
    height: auto;
    /*background-image:url("../assets/login_bg.png");*/
    background-image:url("../assets/login_bg_20240421.png"),linear-gradient(to bottom, #FEAD8D, #FE9267);
    background-repeat: no-repeat;
    background-size: 100vw auto;
    display: flex;
    flex-direction: column;
    /*position: relative;*/
    /*top: 0;*/
    /*left: 0;*/
  }
  .bg_box{
    width: 100vw;
    height: auto;
    /*background-image:url("../assets/login_bg.png");*/
    /*background-image:url("../assets/login_bg_20240421.png");*/
    /*background-repeat: no-repeat;*/
    /*background-size: 100vw auto;*/
    /*position: relative;*/
    /*top: 0;*/
    /*left: 0;*/
  }
  .main_box{
    width: 100vw;
    position: absolute;
    top: 60vh;
  }
  .model_item1{
    width: 90vw;
    margin-left: 5vw;
  }
.model_item2{
  width: 100vw;
  display: flex;flex-direction: column;
  justify-content: center;align-items: center;
  background: linear-gradient(to bottom, #FEA480, #FEA480);

}

.model_item3{
  width: 90vw;
  margin-left: 5vw;
}

  .form_item_text{
    font-size: 14px !important;
    color: #3E0608 !important;
  }
  .input_box{
    background: #fff;
    border-radius: 10px;
    display: flex;flex-direction: column;justify-content: center;align-items: center;
  }
  .div_col{
    display: flex;
    flex-direction: column;
  }
  .div_row{
    display: flex;
    flex-direction: row;
  }
  .input_label {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    flex: none;
    box-sizing: border-box;
    width: 7.2em;
    color: #3E0608;
    text-align: left;
    word-wrap: break-word;
    padding: 10px 16px;
    font-size: 14px;
    margin-right: 0px !important;
  }
  .xy_box{
    -webkit-box-flex: 0;
    -webkit-flex: none;
    flex: none;
    box-sizing: border-box;
    color: #646566;
    text-align: left;
    word-wrap: break-word;
    font-size: 12px;
  }
  .xy_box a{
    color: #0636FE;
  }
  .txt_title{
    font-size: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 31px;
  }
  .txt_content{
    background: #ffffff;
    box-shadow: 0px 4px 5px 0px rgba(237,175,147,0.06);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #FFF2EC;
    padding: 10px 10px;
    margin-top: 16px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #666;
    line-height: 25px;
    text-align: center;
  }
  .title2{
    font-size: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 25px;
    margin-left: 6px;
  }
  .item_box{
    background: #FFFFFF;
    box-shadow: 0px 4px 5px 0px rgba(237,175,147,0.06);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #FFF2EC;
    font-size: 12px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #7A736F;
    line-height: 22px;
    padding: 10px;
    margin-top: 6px;
  }
  .box_bottom{
    background: #FFF2EC;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 11px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #7A736F;
    line-height: 20px;
    padding: 10px;
    text-align: center;
    margin-top: 20px;
  }



.vnp-header {
  height: 40px;
  padding-top: 6px;
  position: relative;
}

.vnp-header .vnp-btn-finish {
  position: absolute;
  right: 0;
  height: 100%;
  padding: 0 16px;
  color: #576b95;
  font-size: 14px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.vnp-input-box-outer {
  width: 82%;
  max-width: 600px;
  margin: 0 auto;
  padding: 10px;
}

.vnp-input-box {
  padding: 10px 0;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  color: #8d8d8d;
  font-size: 15px;
  text-align: center;
}

.vnp-input-box ul {
  display: flex;
}

.vnp-input-box li {
  flex: 1;
  border-right: 1px solid #eaeaea;
  height: 28px;
  line-height: 28px;
}

.vnp-input-box li:first-child {
  border-right: 0;
}

.vnp-input-box li:last-child {
  border: none;
}

.vnp-input-box li.active {
  color: #1989fa;
}

.vnp-input-box li.active>span {
  height: 100%;
  width: 20px;
  display: inline-block;
  border-bottom: 1px solid #1989fa;
}
.vnp-red {
  border: 1px solid red;
}
.vnp-keys {
  padding: 3px;
  background: #f2f3f5;
  padding-bottom: 22px;
}

.vnp-keys .vnp-keys-row {
  display: flex;
  justify-content: center;
}

.vnp-keys .vnp-btn-key-wrapper {
  flex: 0 1 calc((100% - 6px * 10) / 10);
  padding: 3px;
  box-sizing: content-box;
}

.vnp-keys .vnp-btn-key-wrapper.vnp-del-wrapper,
.vnp-keys .vnp-btn-key-wrapper.vnp-type-wrapper {
  flex: 1;
}

.vnp-keys .vnp-btn-key-wrapper.vnp-type-wrapper .vnp-smaller {
  color: #999;
  font-size: 12px;
}

.vnp-keys .vnp-btn-key-wrapper .vnp-btn-key {
  padding: 0;
  width: 100%;
  border-radius: 4px;
}

.vnp-keys .vnp-btn-key-wrapper .vnp-btn-empty {
  background: transparent;
  border: none;
}

.vnp-keys .vnp-btn-key-wrapper .vnp-delete-icon {
  width: 18px;
  vertical-align: middle;
}
.buttomBtn{
  width: 100%;
  height: auto;
  position: fixed;
  bottom: 0;
}
</style>

<style>
.van-cell__title span{
  font-size: 14px !important;
  color: #3E0608 !important;
}
.van-field__label{
  margin-right: 0px !important;
}
.div_col_center{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
}

.wrapper {
  display: flex;
  align-items: end;
  justify-content: center;
  height: 100%;
}

.wrapper_info {
  width: 100vw;
  height: auto;
  background: #ffffff;
  padding: 20px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #333;
}
.wrapper_t1{
  display: flex;
  flex-direction: row;
  font-size: 12px;
  color: #000000;
  align-items: center;
  text-align: center;
  margin-top: 0px;
  font-weight: bold;
}
.wrapper_check{
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  font-size: 12px;
  color: #999999;
}
.wrapper_check .van-checkbox{
  -webkit-box-align: start !important;
  -webkit-align-items: flex-start !important;
  align-items: start !important;
}
.wrapper_btn{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
</style>
